import React from 'react'
import Collapsible from 'react-collapsible';
import { Container, Header } from 'semantic-ui-react'
import MetaTags from 'react-meta-tags';
import listOfPatents from './patents.json';

class Patents extends React.Component {
    state = {
        gridData: listOfPatents
    }

    render() {
        const json = this.state.gridData;
        var arr = [];
        Object.keys(json).forEach(function (key) {
            arr.push(json[key]);
        });
        return (
            <div className="wrapper">
                <MetaTags>
                    <title>Eric Bilange - Patents</title>
                    <meta name="description" content="Technology, LBS, Mobile, Context, Game, Network" />
                    <meta property="og:title" content="Eric Bilange" />
                </MetaTags>
                <Container text style={{ marginTop: '7em' }}>
                    <Header as='h1'>List of Patents</Header>
                    <p>Here is the list of patents I invented or co-invented. Most of them have been granted, some are still pending.</p>
                    <p>Expand the title to see the abstract and then follow the link to the full patent if interested (it will load in a new tab).</p>
                    {arr.map((patent) => <PatentObject key={patent.PatentID} patent={patent} />)}

                </Container>
            </div>
        );
    }
}

class PatentObject extends React.Component {

    render() {
        return <Collapsible trigger={this.props.patent.PatentTitle}>
            {this.props.patent.PatentAbstract} <br /><br />
            <a href={this.props.patent.PatentLink} target="_blank" rel="noopener noreferrer">Full patent here</a>
        </Collapsible>
    }
}

export default Patents