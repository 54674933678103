import React from 'react'
import { Container, Menu, Grid } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import MetaTags from 'react-meta-tags';
import axios from 'axios';

import description from './strings/description.md';

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.getPost = this.getPost.bind(this);
    }
    state = {
        activeItem: 1,
        postList: []
    }

    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name });

    }

    componentDidMount() {
        axios.get(`https://raw.githubusercontent.com/ericbilange/blog/master/list.json?x=` + Math.random())
            .then(res => {
                const posts = res.data;
                this.setState({ postList: posts });
                console.log("Posts: " + posts)
            });
    }

    getPost() {
        return this.state.activeItem;
    }

    render() {
        const active = this.state.activeItem;
        const listMenuItems = this.state.postList.map(entry =>
            <Menu.Item key={entry.id} name={entry.id} active={active === entry.id} onClick={this.handleItemClick} >
                {entry.title}
            </Menu.Item>
        );

        return (
            <div className="wrapper">
                <MetaTags>
                    <title>Eric Bilange - Blog</title>
                    <meta name="description" content="Technology" />
                    <meta property="og:title" content="Eric Bilange" />
                </MetaTags>
                <Container text style={{ marginTop: '7em', marginLeft: '7em' }}>
                    <Grid divided='vertically' columns='equal'>
                        <Grid.Row floated='left' columns={1} relaxed='very' stackable='true'>
                            <Grid.Column width={4}>
                                <Menu fluid vertical tabular>
                                    {listMenuItems}
                                </Menu>
                            </Grid.Column>

                            <Grid.Column floated='right' verticalAlign='top' width={12}>
                                <BlogEntry blogTitle="Test" blogEntry={description} callbackFromParent={this.getPost} />
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </Container>
            </div>
        );
    }
}

class BlogEntry extends React.Component {
    state = {
        id: 0,
        post: ''
    }

    render() {
        var parentId = this.props.callbackFromParent();
        if (parentId !== this.state.id) {
            this.setState({ id: parentId });
            axios.get(`https://raw.githubusercontent.com/ericbilange/blog/master/post` + parentId + ".md?x=" + Math.random())
                .then(res => {
                    const thePost = res.data;
                    this.setState({ post: thePost });
                    console.log("Posts: " + thePost)
                });
        }
        return (
            <ReactMarkdown source={this.state.post} escapeHtml={false} />
        )
    }
}

export default Blog