import React, { Component } from "react";
import { Menu, Image } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import logo from "./logo2.jpg"

class Navbar extends Component {
    render() {
        return (
            <Menu fixed="top" inverted>
                <Menu.Item name='home' as={Link} header to="/" >
                    <Image size="mini" src={logo} circular style={{ marginRight: '1.5em' }}/> Home 
                </Menu.Item>
                <Menu.Item as={Link} to="/cv" content="Bio" />
                <Menu.Item as={Link} to="/patents" content="Patents" />
                <Menu.Item as={Link} to="/blog" content="Blog" />
            </Menu>
        )
    };
};

export default Navbar