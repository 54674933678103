import React from 'react'
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import MetaTags from 'react-meta-tags';

class Cv extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <MetaTags>
                    <title>Eric Bilange - Bio</title>
                    <meta name="description" content="Technology, Executive, CTO, Engineering, EVP, SVP, Turn arounds, Startups" />
                    <meta property="og:title" content="Eric Bilange" />
                </MetaTags>
                <div id="main-page" style={{
                    left: '50%'
                }}>
                    <h1>Eric Bilange's CV</h1>
                    <Timeline lineColor={'#ddd'}>
                        <TimelineItem
                            key="001"
                            dateText="2017 – Now"
                            dateInnerStyle={{ background: '#E8E8E8', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>Operating Partner, HG Capital</h3>
                            <h4>London</h4>
                            As an Operating Partner, Eric provides expertise to CPOs and CTOs in Product Development, Agile, SAFe, LeSS
                            and new technology trends in the area of Mobile, Cloud, Machine Learning, and Cyber Security.
                    </TimelineItem>
                        <TimelineItem
                            key="002"
                            dateText="2016 – 2018"
                            dateInnerStyle={{ background: '#D3D3D3', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>Technical Advisor and interim CTO, Privowny</h3>
                            <h4>London &amp; France</h4>
                            Built the Engineering team, rearchitected the product, redesign a technical roadmap, MVP and product roadmap.
                                Introduction of Machine Learning to understand forms in any web document.
                    </TimelineItem>
                        <TimelineItem
                            key="003"
                            dateText="2013 – 2016"
                            dateInnerStyle={{ background: '#E8E8E8', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>CTO, Xura (NAS: MESG)</h3>
                            <h4>London</h4>
                            Lead Innovation Business Unit. P&amp;L responsibility. Acquisitions: WebRTC, SMS<br />
                            From 2013-2014: responsible responsible for the Professional Services - 450 consultants world wide. Re-established profitability
                                and customer satisfaction.
                    </TimelineItem>
                        <TimelineItem
                            key="004"
                            dateText="2006 – 2013"
                            dateInnerStyle={{ background: '#D3D3D3', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>Sr Director Engineering, Qualcomm</h3>
                            <h4>San Diego, CA</h4>
                            From 2010 to 2013: co-founded Qualcomm Labs and successfully incubated Gimbal. Mentored other projects.<br />
                            From 2006 to 2010: lead Brew engineering - introduced Agile, restructured the engeineering departmnet, tranformed
                                product development approach and re-instated customer satisfaction.
                    </TimelineItem>
                        <TimelineItem
                            key="005"
                            dateText="1999 – 2006"
                            dateInnerStyle={{ background: '#E8E8E8', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>CTO and co-founder, Mforma/Handson</h3>
                            <h4>San Diego, CA</h4>
                            Grew the company from the ground up (600+ employees worldwide). Leading game developer, aggregator and platform vendor in mobile entertainment.
                    </TimelineItem>
                        <TimelineItem
                            key="006"
                            dateText="1997-199"
                            dateInnerStyle={{ background: '#D3D3D3', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>Product Management Director, Alcatel</h3>
                            <h4>Paris, France</h4>
                            Product owner for an Intenet services platform for Cell phones and set-top boxes. Successfully launched with European telecommunication operators.
                    </TimelineItem>
                        <TimelineItem
                            key="007"
                            dateText="1995 – 1997"
                            dateInnerStyle={{ background: '#E8E8E8', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>Director Professional Services, Netscape Communication</h3>
                            <h4>Paris, France</h4>
                            Started the Southern European services teams, delivering ISP platforms to telecommunication operators, Intranets and Extranet (e.g., Reanult's dealership system)
                    </TimelineItem>
                        <TimelineItem
                            key="008"
                            dateText="1991-1995"
                            dateInnerStyle={{ background: '#D3D3D3', color: '#000' }}
                            bodyContainerStyle={{
                                background: '#ddd',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3>Various R&amp;D positions, Cap Gemini</h3>
                            <h4>Paris, France</h4>
                            Home banking internet application project director<br />
                            Cap Gemini Intranet development director<br />
                            Machine translation and Natural Language processing R&amps;D projects
                    </TimelineItem>
                    </Timeline>
                </div>
            </div>
        )
    }
}

export default Cv