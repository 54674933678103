import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container, Header, Segment, Grid, Divider, Image } from 'semantic-ui-react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import MetaTags from 'react-meta-tags';
import './App.scss';
import Cv from "./cv.js";
import Patents from "./patents.js";
import Navbar from "./navbar.js";
import "semantic-ui-css/semantic.min.css";
import me from "./me.jpg";
import Mailto from 'react-protected-mailto'
import Blog from "./blog.js"
library.add(faInfinity)

class App extends Component {
  render() {
    return (
      <Router>
        <Container className="main">
          <Navbar />
          <Container className="main-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/cv" component={Cv} />
              <Route path="/patents" component={Patents} />
              <Route path="/blog" component={Blog} />
              <Route component={Notfound} />
            </Switch>
          </Container>
        </Container>
      </Router>
    )
  }

}

const Home = () => {
  return (
    <div className="wrapper">
      <MetaTags>
        <title>Eric Bilange - Home</title>
        <meta name="description" content="Technology, Software, AI, ML, Digital, Mobile" />
        <meta property="og:title" content="Eric Bilange" />
      </MetaTags>
      <Container text style={{ marginTop: '7em' }}>
        <Header as='h1'>Welcome</Header>
        <Segment placeholder>
          <Grid divided='vertically'>
            <Grid.Row columns={2} relaxed='very' stackable>
              <Divider vertical> <FontAwesomeIcon icon="infinity" /> </Divider>
              <Grid.Column>
                <Image src={me} size='medium' circular />
              </Grid.Column>

              <Grid.Column verticalAlign='middle'>
                <div className="centerContent">
                  <div className="selfCenter standardWidth">
                    <TwitterTimelineEmbed
                      sourceType="profile"
                      screenName="ericbilange"
                      options={{ height: 400 }}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <Mailto email="eric@bilange.com" obfuscate={true}>
                  <p>Email me!</p>
                </Mailto>
              </Grid.Column>
              <Grid.Column>
                <p><i>We owe a lot to Thomas Edison - if it wasn't for him, we'd be surfing the net by candlelight.</i></p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Segment>

      </Container>
    </div>
  )
}


const Notfound = () => <h1>Not found</h1>


export default App;
